<template>
	<!-- Modal Button (Assuming you want to open the modal using a button) -->
	<button
		type="button"
		role="button"
		class="btn btn-primary btn-sm shadow-0 create-dialog-btn btn-rounded"
		@click.stop="(dialog = !dialog), (files = [])"
	>
		<span class="create-btn-text">
			<FontAwesomeIcon :icon="lookupIcon('cloud-arrow-up', 'fas')" />
			Upload
		</span>
	</button>

	<!-- MDB Modal -->
	<MDBModal v-model="dialog" size="lg">
		<MDBModalBody>
			<button
				class="float-end btn border border-1 text-black shadow-0 btn-floating btn-sm"
				type="button"
				@click.prevent="dialog = false"
			>
				<IconBadge icon="fat.xmark" size="lg" :options="{ classList: [] }" />
			</button>
			<h4 class="text-center mt-3 fw-bold">Upload an Asset</h4>
			<h6 class="text-center fw-light mb-4"></h6>
			<div
				v-if="loading"
				class="progress"
				:style="{ opacity: loading ? 1 : 0 }"
			>
				<div
					class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					style="width: 100%"
				></div>
			</div>
			<div
				v-if="!loading"
				class="d-flex flex-column gap-4"
				style="max-width: 650px; margin: auto"
			>
				<div
					class="dropzone-container"
					@dragover="dragover"
					@dragleave="dragleave"
					@drop="drop"
				>
					<input
						id="fileInput"
						ref="file"
						type="file"
						multiple
						name="file"
						class="hidden-input"
						accept=".pdf,.jpg,.jpeg,.png"
						@change="onChange"
					/>
					<label for="fileInput" class="file-label text-center my-3">
						<div v-if="isDragging">Release to drop files here.</div>
						<div v-else class="lh-1 d-flex flex-column gap-2">
							<img
								src="/images/asset_upload.png"
								alt="Upload Image"
								class="mx-auto"
								style="max-width: 60px"
							/>
							<h5 class="fw-bold mb-0" style="font-size: 1.125rem">
								Drag File Here
							</h5>
							<div style="font-size: 0.75rem">Or</div>
							<div
								style="font-size: 0.875rem"
								class="btn-outline-primary rounded-pill align-self-center"
							>
								<IconBadge icon="far.cloud-arrow-up" />
								<span class="fw-bold">Upload from Computer</span>
							</div>
						</div>
					</label>
					<div v-if="files.length" class="preview-container mt-4 row">
						<MDBAccordion>
							<MDBAccordionItem>
								<div
									v-for="file in files"
									:key="file.file.name"
									:title="file.file.name"
									class="row align-items-center"
								>
									<div class="col-1">
										<MDBBtn
											class="btn-danger btn-sm ml-2"
											type="button"
											title="Remove file"
											@click="remove(files.indexOf(file))"
										>
											&times;
										</MDBBtn>
									</div>
									<div class="col-3">
										<img
											class="preview-img"
											:src="generateThumbnail(file.file)"
										/>
									</div>
									<div class="col-8">
										<MDBInput
											v-model="file.name"
											label="Rename File"
											:form-outline="false"
											wrapper-class="form-floating"
										/>
										<div><b>Size:</b> {{ file.file.size / 1000 }} KB</div>
									</div>
								</div>
							</MDBAccordionItem>
						</MDBAccordion>
					</div>
				</div>
				<MDBBtn
					:disabled="files.length === 0"
					color="primary"
					class="btn btn-primary m-auto ripple-surface"
					@click="onSubmit"
				>
					Upload
				</MDBBtn>
			</div>
		</MDBModalBody>
	</MDBModal>
</template>

<script>
import { useAssetStore } from "@/Store/assetStore"
import { useProjectStore } from "@/Store/projectStore"
import { router } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import IconBadge from "@/Components/IconBadge.vue"

import {
	MDBAccordion,
	MDBAccordionItem,
	MDBBtn,
	MDBCol,
	MDBContainer,
	MDBInput,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBRow,
} from "mdb-vue-ui-kit"
import axios from "axios"
import route from "ziggy-js"
import { storeToRefs } from "pinia"

export default {
	components: {
		MDBBtn,
		MDBModal,
		MDBModalBody,
		MDBAccordion,
		MDBAccordionItem,
		MDBInput,
		FontAwesomeIcon,
		IconBadge,
	},
	props: {
		formDefinition: {
			type: Object,
			default: () => {
				return {}
			},
		},
		size: {
			type: String,
			default: "xl",
		},
		showOnMounted: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["success"],
	data() {
		return {
			dialog: false,
			isDragging: false,
			files: [],
			lookupIcon: lookupIcon,
			loading: false,
		}
	},
	methods: {
		onSubmit() {
			const formData = new FormData()
			let uploadRoute = ""
			if (this.formDefinition?.create) {
				uploadRoute = route(
					this.formDefinition.create.route,
					this.formDefinition.create.params,
				)
				let assetFileName = "asset[]"
				if (this.formDefinition.assetFileName) {
					assetFileName = this.formDefinition.assetFileName + "[]"
				}
				this.files.forEach((file) => {
					formData.append(assetFileName, file.file)
				})
			} else {
				const projectStore = useProjectStore()
				const project_id = projectStore.getActiveProject.slug
				const assetStore = useAssetStore()

				this.files.forEach((file) => {
					formData.append("asset[]", file.file)
				})
				uploadRoute = `/projects/${project_id}/assets`
			}
			const toast = useToast()

			router.post(uploadRoute, formData, {
				onSuccess: (event) => {
					toast.success("Successfully uploaded assets")
					this.$emit("success")
				},
				onError: (errors) => {
					toast.error(Object.values(errors).join("\n"))
				},
			})

			this.files = []
			this.dialog = false
		},
		onChange() {
			Array.from(this.$refs.file.files).forEach((file) => {
				this.files.push({ file: file, name: file.name })
			})
		},
		generateThumbnail(file) {
			const fileSrc = URL.createObjectURL(file)
			setTimeout(() => {
				URL.revokeObjectURL(fileSrc)
			}, 1000)
			return fileSrc
		},
		remove(i) {
			this.files.splice(i, 1)
		},
		dragover(e) {
			e.preventDefault()
			this.isDragging = true
		},
		dragleave() {
			this.isDragging = false
		},
		drop(e) {
			e.preventDefault()
			this.$refs.file.files = e.dataTransfer.files
			this.onChange()
			this.isDragging = false
		},
	},
}
</script>

<style lang="css" scoped>
.main {
	display: flex;
	flex-grow: 1;
	align-items: center;
	height: 100vh;
	justify-content: center;
	text-align: center;
}
.dropzone-container {
	border: 1px dashed #222222;
	max-width: 650px;
	margin: auto;
	width: 100%;
}
.hidden-input {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
}
.file-label {
	font-size: 20px;
	display: block;
	cursor: pointer;
}
.preview-container {
	display: flex;
	margin-top: 2rem;
}
.preview-card {
	display: flex;
	border: 1px solid #a2a2a2;
	padding: 5px;
	margin-left: 5px;
}
.preview-img {
	width: 100%;
	height: auto;
	border-radius: 5px;
	border: 1px solid #a2a2a2;
	background-color: #a2a2a2;
}
@media (max-width: 1000px) {
	:deep(.modal-dialog.modal-xl) {
		--mdb-modal-width: 80%;
	}
}
</style>
